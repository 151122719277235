






import Vue from 'vue'
import Component from 'vue-class-component'
import SygniAccessModal from "@/components/layout/SygniAccessModal.vue";
import { BTable } from 'bootstrap-vue';
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
// import { AxiosRequestConfig } from 'axios';

@Component({
  components: { BTable, SygniRoundedButton, SygniAccessModal }
})
export default class accountingModule extends Vue {}
