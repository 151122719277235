var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "statement-module"
  }, [_c('sygni-access-modal')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }