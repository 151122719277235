































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import {Prop} from 'vue-property-decorator';
import {BSpinner} from "bootstrap-vue";

Component.registerHooks(['validations'])
@Component({
  components: {SygniRoundedButton, BSpinner}
})

export default class SygniModal extends Vue {
  @Prop() header: string;
  @Prop() description?: string;
  @Prop({default: 'normal'}) size?: string;
  @Prop({default: 'Quit without saving'}) cancelText?: string;
  @Prop({default: 'Save changes & quit'}) confirmText?: string;
  @Prop() isLoading!: boolean;

  get hasContentSlot(): boolean {
    return !!this.$slots.content;
  }
}
